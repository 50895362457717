<template>
  <div>
    <div class="header">shuhan GPT</div>
    <div class="frament" v-for="item,index in generatedTexts" :key="index">
      <div class="question">你的提问：<br /><br />{{ item.question }}</div>
      <div class="reply">GPT:{{ item.reply }}</div>
    </div>
    <textarea class="input" v-model="inputText"></textarea>
    <button class="button" @click="generateText" v-if="!isLoad">Send GPT</button>
    <p class="loading" v-else>loading...</p>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'GptDemo',
  data() {
    return {
      inputText: '',
      generatedTexts: [],
      isLoad: false
    }
  },
  methods: {
    async generateText() {
      try {
        this.isLoad = true
        const response = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-3.5-turbo',
            messages: [{ role: 'user', content: this.inputText }],
            temperature: 0.7
          },
          {
            headers: {
              'Content-Type': 'application/json',
              // Authorization: 'Bearer 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'
              Authorization: 'Bearer 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'
            }
          }
        )
        console.log('打印回复', response)
        let reply = response.data.choices[0].message.content
        let question = this.inputText
        this.generatedTexts.push({ reply, question })
        this.inputText = ''
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoad = false
      }
    }
  }
}
</script>
<style>
.header {
  text-align: center;
  margin-bottom: 20px;
}
.question {
  white-space: pre-line;
  border-bottom: 1px #999 solid;
  padding: 10px;
  border-radius: 5px;
  background: #458ba1;
  color: #fff;
  margin-bottom: 4px;
}
.reply {
  white-space: pre-line;
  padding: 10px;
  border-radius: 5px;
  background: #799d76;
  color: #fff;
}
.frament {
  white-space: pre-line;
  border-bottom: 1px #999 solid;
  padding: 10px 0px;
}
.input {
  width: 100%;
  display: block;
  height: 50px;
}
.button {
  width: 100%;
  display: block;
  margin-top: 20px;
  background: #2cbf7b;
  color: #fff;
  border: none;
  padding: 16px;
  border-radius: 12px;
}
.loading {
  text-align: center;
}
</style>
